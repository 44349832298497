import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ProspectDate from './ProspectDate';
import ProspectSelect from './ProspectSelect';
import { ProspectContext } from '../../contexts/ProspectContext';
import { Flex } from '../primitives';

export default function ProspectModal({ refreshFeed, refreshFeedOnSubmit }) {
  const [state, dispatch] = useContext(ProspectContext);

  const stageValue = state.stage.options.find((o) => o.active)?.value;

  const disabled = stageValue === '-';
  const active = state.prospectId !== null;

  return (
    <Modal
      backdrop="static"
      show={state.modal === 1}
      onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
      className="tv-modal tv-company-modal"
    >
      <Modal.Header>
        <Modal.Title>{active ? 'Update' : 'Create'} Prospect</Modal.Title>
        <Button
          className="modal-close"
          onClick={() =>
            dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
          }
        >
          <i className="fa fa-times" />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <ProspectSelect column="owner" />
        <ProspectDate />
        <ProspectSelect column="status" refreshFeed={() => !refreshFeedOnSubmit && refreshFeed()} />
        <ProspectSelect column="stage" />
        <ProspectSelect column="source" />
        <ProspectSelect column="sourceType" />
        <ProspectSelect column="awareTv" />
      </Modal.Body>

      <Modal.Footer>
        <Flex justify="space-between" fill align="center">
          {active && (
            <Button
              onClick={() => {
                if (refreshFeedOnSubmit && refreshFeed) refreshFeed();
                dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } });
              }}
            >
              Done
            </Button>
          )}
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 2, isEditing: state.isEditing } })
            }
            disabled={disabled}
          >
            Next: {active ? 'Update' : 'Add'} Scores
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
