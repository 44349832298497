import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  ResponsiveContainer,
  ComposedChart,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  Line,
  Bar,
  Scatter,
} from 'recharts';
import { LayoutHeader } from '../components/primitives';
import { getStats } from '../BackendAPI';
import '../styles/components/ProspectStats.css';
import captureError from '../utils/sentry';

const Container = styled.div`
  padding-bottom: 80px;
`;

const COLOR_PALETTE = [
  '#c0bbc9',
  '#89fa9c',
  '#77cf7c',
  '#77af9c',
  '#285943',
  '#0d1f2d',
  '#546a7b',
  '#3f7cac',
  '#7c6c77',
  '#b8b3e9',
  '#694a53',
  '#fd1e6e',
  '#9f2108',
  '#ebd469',
  '#1f3ca6',
  '#5c82d2',
  '#fea53b',
  '#6314af',
  '#f76015',
  '#a586fe',
];
const BRUSH_COLOR = '#3581b8';
const CURRENT_YEAR = new Date().getFullYear();

function getCurrentQuarter() {
  const month = new Date().getMonth();
  return Math.floor(month / 3) + 1;
}

const CURRENT_QUARTER = getCurrentQuarter();

function parseData(rawData) {
  let prospectsPerQuarter = {};
  let averageInvestorScore = {};
  let prospectsByStage = {};
  let prospectsBySource = {};
  let stageCategories = new Set();
  let sourceCategories = new Set();
  let prospectsBySourceType = {};
  let sourceTypeCategories = new Set();
  let prospectsByAwareness = {};
  let awarenessCategories = new Set();
  let prospectsByTeamMemberYTD = {};
  let prospectsByTeamMember3Mo = {};

  for (let i = 0; i < rawData.length; i += 1) {
    const entry = rawData[i];
    const year = entry.prospect_year;
    const quarter = entry.prospect_quarter;
    const period = `${year}${quarter}`;
    const companyScore = entry.tv_company_score;
    const investorScore = parseFloat(entry.tv_company_investor_score);
    const stage = entry.tv_stage === null ? ' N/A' : entry.tv_stage;
    const source = entry.source === 'N/A' ? ' N/A' : entry.source;
    stageCategories.add(stage);
    sourceCategories.add(source);
    const sourceType =
      entry.source_type === null || entry.source_type === 'N/A' ? ' N/A' : entry.source_type;
    sourceTypeCategories.add(sourceType);
    const awareness =
      entry.aware_tv === null || entry.aware_tv === 'N/A' ? ' N/A' : entry.aware_tv;
    awarenessCategories.add(awareness);
    const owner = entry.tv_responsibility;

    const quarterCount = prospectsPerQuarter.hasOwnProperty(period)
      ? prospectsPerQuarter[period]
      : { period, prospects: 0, highScores: 0 };

    const scoreCount = averageInvestorScore.hasOwnProperty(period)
      ? averageInvestorScore[period]
      : { period, sum: 0, scored: 0 };

    const stageCount = prospectsByStage.hasOwnProperty(period)
      ? prospectsByStage[period]
      : { period, prospects: 0 };

    const sourceCount = prospectsBySource.hasOwnProperty(period)
      ? prospectsBySource[period]
      : { period, prospects: 0 };

    const sourceTypeCount = prospectsBySourceType.hasOwnProperty(period)
      ? prospectsBySourceType[period]
      : { period, prospects: 0 };
    
    const awarenessCount = prospectsByAwareness.hasOwnProperty(period)
      ? prospectsByAwareness[period]
      : { period, prospects: 0 };

    const responsibilityCount = prospectsByTeamMemberYTD.hasOwnProperty(owner)
      ? prospectsByTeamMemberYTD[owner]
      : { name: owner, prospects: 0, totalScore: 0 };

    const quarterlyCount = prospectsByTeamMember3Mo.hasOwnProperty(owner)
      ? prospectsByTeamMember3Mo[owner]
      : { name: owner, prospects: 0, totalScore: 0 };

    quarterCount.prospects += 1;
    if (companyScore >= 7) quarterCount.highScores += 1;
    if (!Number.isNaN(investorScore)) {
      scoreCount.scored += 1;
      scoreCount.sum += investorScore;
    }

    stageCount.prospects += 1;
    if (!stageCount.hasOwnProperty(stage)) stageCount[stage] = 0;
    stageCount[stage] += 1;
    
    sourceCount.prospects += 1;
    if (!sourceCount.hasOwnProperty(source)) sourceCount[source] = 0;
    sourceCount[source] += 1;
    
    sourceTypeCount.prospects += 1;
    if (!sourceTypeCount.hasOwnProperty(sourceType)) sourceTypeCount[sourceType] = 0;
    sourceTypeCount[sourceType] += 1;
    
    awarenessCount.prospects += 1;
    if (!awarenessCount.hasOwnProperty(awareness)) awarenessCount[awareness] = 0;
    awarenessCount[awareness] += 1;

    if (year === CURRENT_YEAR) {
      responsibilityCount.totalScore += companyScore;
      responsibilityCount.prospects += 1;
    }

    if (quarter === CURRENT_QUARTER && year === CURRENT_YEAR) {
      quarterlyCount.totalScore += companyScore;
      quarterlyCount.prospects += 1;
    }

    prospectsPerQuarter[period] = quarterCount;
    averageInvestorScore[period] = scoreCount;
    prospectsByStage[period] = stageCount;
    prospectsBySource[period] = sourceCount;
    prospectsBySourceType[period] = sourceTypeCount;
    prospectsByAwareness[period] = awarenessCount;
    prospectsByTeamMemberYTD[owner] = responsibilityCount;
    prospectsByTeamMember3Mo[owner] = quarterlyCount;
  }

  prospectsPerQuarter = Object.values(prospectsPerQuarter);
  averageInvestorScore = Object.values(averageInvestorScore);
  prospectsByStage = Object.values(prospectsByStage);
  prospectsBySource = Object.values(prospectsBySource);
  stageCategories = Array.from(stageCategories);
  sourceCategories = Array.from(sourceCategories);
  prospectsBySourceType = Object.values(prospectsBySourceType);
  prospectsByAwareness = Object.values(prospectsByAwareness);
  sourceTypeCategories = Array.from(sourceTypeCategories);
  awarenessCategories = Array.from(awarenessCategories);
  prospectsByTeamMemberYTD = Object.values(prospectsByTeamMemberYTD).filter(
    (obj) => obj.prospects > 0,
  );
  prospectsByTeamMember3Mo = Object.values(prospectsByTeamMember3Mo).filter(
    (obj) => obj.prospects > 0,
  );
  prospectsPerQuarter.map((entry) => {
    entry.highScorePercentage = (entry.highScores / entry.prospects) * 100;
    return entry;
  });
  averageInvestorScore.forEach((entry) => {
    entry.averageScore = entry.sum / entry.scored;
    return entry;
  });

  for (const entry of prospectsByStage) {
    for (const key in entry) {
      if (key === 'period' || key === 'prospects') continue;
      entry[key] = (entry[key] / entry.prospects) * 100;
    }
  }
  for (const entry of prospectsBySource) {
    for (const key in entry) {
      if (key === 'period' || key === 'prospects') continue;
      entry[key] = (entry[key] / entry.prospects) * 100;
    }
  }
  for (const entry of prospectsBySourceType) {
    for (const key in entry) {
      if (key === 'period' || key === 'prospects') continue;
      entry[key] = (entry[key] / entry.prospects) * 100;
    }
  }
  for (const entry of prospectsByAwareness) {
    for (const key in entry) {
      if (key === 'period' || key === 'prospects') continue;
      entry[key] = (entry[key] / entry.prospects) * 100;
    }
  }
  for (const member of prospectsByTeamMemberYTD) {
    member.averageScore = member.totalScore / member.prospects;
  }

  return {
    prospectsPerQuarter,
    averageInvestorScore,
    prospectsByStage,
    prospectsBySource,
    stageCategories,
    sourceCategories,
    prospectsBySourceType,
    prospectsByAwareness,
    sourceTypeCategories,
    awarenessCategories,
    prospectsByTeamMemberYTD,
    prospectsByTeamMember3Mo,
  };
}

const periodFormatter = (period) => `Q${period[4]} ${period.substring(0, 4)}`;
const percentageFormatter = (value) => `${value.toFixed(1)}%`;
const tenthsFormatter = (value) => `${value.toFixed(1)}`;
const nameFormatter = (fullName) => `${fullName.split(/(\s+)/)[0]}`;

function createStackedBars(categories) {
  const bars = [];
  categories.sort((a, b) => a.localeCompare(b));
  for (let i = 0; i < categories.length; i += 1) {
    bars.push(
      <Bar
        name={`% ${categories[i]}`}
        dataKey={categories[i]}
        stackId=""
        formatter={percentageFormatter}
        fill={COLOR_PALETTE[i + 2]}
        barSize={20}
        key={categories[i]}
      />,
    );
  }
  return bars;
}

function ProspectsPerQuarter(props) {
  const data = props.data.prospectsPerQuarter;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" unit="%" />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        <Bar
          yAxisId="left"
          name="# Prospects"
          dataKey="prospects"
          fill={COLOR_PALETTE[16]}
          barSize={20}
        />
        <Line
          yAxisId="right"
          name="% 7 or Higher"
          dataKey="highScorePercentage"
          formatter={percentageFormatter}
          stroke={COLOR_PALETTE[17]}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function AverageInvestorScore(props) {
  const data = props.data.averageInvestorScore;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis yAxisId="left" tickFormatter={(number) => `${number.toLocaleString()}`} />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        <Bar
          yAxisId="left"
          name="Average Investor Score"
          dataKey="averageScore"
          formatter={(value) => `${value.toFixed(2)}`}
          fill={COLOR_PALETTE[18]}
          barSize={20}
        />
        <Line
          yAxisId="right"
          name="# Scored"
          dataKey="scored"
          stroke={COLOR_PALETTE[19]}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function ProspectsByStage(props) {
  const data = props.data.prospectsByStage;
  const categories = props.data.stageCategories;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis unit="%" domain={[0, 100]} tickFormatter={(number) => number.toFixed(0)} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        {createStackedBars(categories)}
      </BarChart>
    </ResponsiveContainer>
  );
}

function ProspectsBySource(props) {
  const data = props.data.prospectsBySource;
  const categories = props.data.sourceCategories;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis unit="%" domain={[0, 100]} tickFormatter={(number) => number.toFixed(0)} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        {createStackedBars(categories)}
      </BarChart>
    </ResponsiveContainer>
  );
}

function ProspectsByOutreachType(props) {
  const data = props.data.prospectsBySourceType;
  const categories = props.data.sourceTypeCategories;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis unit="%" domain={[0, 100]} tickFormatter={(number) => number.toFixed(0)} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        {createStackedBars(categories)}
      </BarChart>
    </ResponsiveContainer>
  );
}

function ProspectsByAwareness(props) {
  const data = props.data.prospectsByAwareness;
  const categories = props.data.awarenessCategories;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-10}
        />
        <YAxis unit="%" domain={[0, 100]} tickFormatter={(number) => number.toFixed(0)} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          height={30}
          startIndex={Math.max(0, data.length - 8)}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        {createStackedBars(categories)}
      </BarChart>
    </ResponsiveContainer>
  );
}

function ProspectsByTeamMemberYTD(props) {
  const data = props.data.prospectsByTeamMemberYTD;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 12 }}
          minTickGap={-10}
          tickFormatter={nameFormatter}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="prospects" name="# Prospects year to date" fill={COLOR_PALETTE[2]} />
        <Scatter
          dataKey="averageScore"
          name="Average Score"
          legendType="none"
          opacity={0}
          formatter={tenthsFormatter}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function ProspectsByTeamMember3Mo(props) {
  const data = props.data.prospectsByTeamMember3Mo;
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 12 }}
          minTickGap={-10}
          tickFormatter={nameFormatter}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="prospects"
          name={`# Prospects from Q${CURRENT_QUARTER}`}
          fill={COLOR_PALETTE[3]}
        />
        <Scatter
          dataKey="averageScore"
          name="Average Score"
          legendType="none"
          opacity={0}
          formatter={tenthsFormatter}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function ProspectStats() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getStats()
      .then((res) => setData(parseData(res)))
      .catch(() => captureError('Error getting prospect stats'));
  }, []);

  return (
    <div>
      <Helmet>
        <title>Prospect Stats - TV CRM</title>
      </Helmet>

      <LayoutHeader>
        <h2>Prospect Stats</h2>
      </LayoutHeader>

      <Container className="container">
        {data !== null && (
          <div>
            <div className="stats-container">
              <div className="chart-container">
                <h3>Prospects Per Quarter</h3>
                <ProspectsPerQuarter data={data} />
              </div>
              <div className="chart-container">
                <h3>Average Investor Score</h3>
                <AverageInvestorScore data={data} />
              </div>
            </div>
            <div className="stats-container">
              <div className="chart-container">
                <h3>Prospects By Stage</h3>
                <ProspectsByStage data={data} />
              </div>
              <div className="chart-container">
                <h3>Prospects By Source</h3>
                <ProspectsBySource data={data} />
              </div>
            </div>
            <div className="stats-container">
              <div className="chart-container">
                <h3>Prospects By Team Member (Past Quarter)</h3>
                <ProspectsByTeamMember3Mo data={data} />
              </div>
              <div className="chart-container">
                <h3>Prospects By Team Member (YTD)</h3>
                <ProspectsByTeamMemberYTD data={data} />
              </div>
            </div>

            <div className="stats-container">
              <div className="chart-container">
                <h3>Prospects By Outreach Type</h3>
                <ProspectsByOutreachType data={data} />
              </div>
              <div className="chart-container">
                <h3>Prospects By Thomvest Awareness</h3>
                <ProspectsByAwareness data={data} />
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default memo(ProspectStats);
