import React, { useContext, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ProspectContext } from '../../contexts/ProspectContext';
import { CompanyVerticalContext } from '../../contexts/CompanyVerticalContext';
import ProspectScore from './ProspectScore';
// import { PROSPECT_SCORES } from '../utils/prospectScores';
import { STAGE_TO_TYPE_MAP } from '../../constants';
import {
  createNewProspect,
  createNewProspectScores,
  patchCompany,
  createNewFeedItem,
} from '../../BackendAPI';
import { getUserFirstName } from '../../utils/Auth';

const getActiveValue = (list) => {
  return list.find((l) => l.active)?.value;
};

export default function ProspectScoresModal({ refreshFeed }) {
  const [state, dispatch] = useContext(ProspectContext);
  const [verticalState, verticalDispatch] = useContext(CompanyVerticalContext);
  const stageValue = getActiveValue(state.stage.options);
  const stageType = STAGE_TO_TYPE_MAP[stageValue];
  const scores = state.prospectScores.filter((s) => s.stage_type_id === stageType);
  const active = !!state.prospectId;

  const createProspect = useCallback(async () => {
    const { permalink, owner, stage, source, sourceType, awareTv, initialContactDate } = state;

    const newProspect = await createNewProspect({
      permalink,
      owner: getActiveValue(owner.options),
      initial_contact_date: initialContactDate.value,
      stage: getActiveValue(stage.options),
      stage_type_id: stageType,
      source: getActiveValue(source.options),
      source_type: getActiveValue(sourceType.options),
      aware_tv: getActiveValue(awareTv.options),
    });

    const prospectId = newProspect.id;
    dispatch({ type: 'ADD_PROSPECT_ID', payload: { prospectId } });

    await createNewProspectScores({ prospect_id: prospectId, prospect_scores: scores });

    const totalScore = scores.map((s) => s.value).reduce((a, b) => a + b, 0);

    await patchCompany(permalink, { tv_company_score: totalScore });
    await patchCompany(permalink, { major_status: 'Active' });

    const firstName = getUserFirstName();
    const header = `updated to Prospect by ${firstName}`;

    await createNewFeedItem({
      permalink,
      type: 'status',
      header,
      custom: `{"status": "Prospect", "user": "${firstName}"}`,
    });

    await refreshFeed();
  }, [dispatch, scores, stageType, state, refreshFeed]);

  const openVerticalModal = useCallback(async () => {
    if (!active) {
      await createProspect();
    }

    const totalScore = scores.map((s) => s.value).reduce((a, b) => a + b, 0);
    await patchCompany(state?.permalink, { tv_company_score: totalScore });

    dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } });
    verticalDispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: true });
  }, [active, scores, state?.permalink, createProspect, dispatch, verticalDispatch]);

  const verticalButtonCopy = useMemo(() => {
    if (active) {
      return state.isEditing ? 'Edit Verticals' : 'Update Verticals';
    }
    return 'Create Prospect';
  }, [state.isEditing, active]);

  return (
    <div>
      <Modal
        backdrop="static"
        show={state.modal === 2}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
        className="tv-modal tv-company-modal"
      >
        <Modal.Header>
          <Modal.Title>Scoring: {stageType === 1 ? 'Early' : 'Late'} Stage</Modal.Title>
          <Button
            className="modal-close"
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
            }
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-spaced">
            {scores.map((s) => (
              <ProspectScore score={s} key={s.score_id} stageType={stageType} />
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: state.isEditing } })
            }
          >
            Go Back
          </Button>
          {state?.isEditing ? (
            <Button
              onClick={() =>
                dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
              }
            >
              Submit
            </Button>
          ) : (
            <Button onClick={() => openVerticalModal()}>{verticalButtonCopy}</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
