import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { differenceInDays, differenceInMonths, isSameDay } from 'date-fns';
import { useLocation } from 'react-router-dom';

import useIsMobile from '../hooks/useIsMobile/useIsMobile';
import { getEngagedCompanies, getProspectData, getSheetsData, getUsers } from '../BackendAPI';
import ListCompanyView from '../components/ListCompanyView';
import '../styles/components/Engaged.css';
import { convertToCSV, downloadCSV, getToday, isRecent } from '../util';
import PageHeader from '../components/PageHeader';
import { Flex, LinkButton, Button } from '../components/primitives';
import FeedFilter from '../components/FeedFilter';
import {
  SPREADSHEET_ID,
  formatMonetaryValue,
  parseNum,
} from '../components/company/CompanyOwnership';
import captureError from '../utils/sentry';

const PROSPECT_COLUMNS = [
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  { colClass: 'description', text: 'Description', key: 'short_description', sortable: false },
  { colClass: 'owner', text: 'Owner', key: 'code', sortable: true },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'subvertical', text: 'Sub-Vertical', key: 'subvertical', sortable: true },
  { colClass: 'stage', text: 'Stage', key: 'last_investment_type', sortable: true },
  { colClass: 'daysOutstanding', text: 'TV Score', key: 'tv_company_score', sortable: true },
  {
    colClass: 'daysOutstanding',
    text: 'VC Score',
    key: 'tv_company_investor_score',
    sortable: true,
  },
  { colClass: 'daysOutstanding', text: 'D/O', key: 'days_outstanding', sortable: true },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
];

const PORTFOLIO_COLUMNS = [
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  // { colClass: 'owner', text: 'Owner', key: 'code', sortable: true },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'statusDate', text: 'Portfolio Date', key: 'status_updated_date', sortable: true },
  { colClass: 'dollarsInvested', text: 'Invested', key: 'dollars_invested', sortable: true },
  { colClass: 'stage', text: 'Stage', key: 'last_investment_type', sortable: true },
  {
    colClass: 'monthsSinceInvestment',
    text: 'Round Date',
    key: 'months_since_last_investment',
    sortable: true,
  },
  { colClass: 'location', text: 'Location', key: 'location', sortable: true },
];

const OTHER_COLUMNS = [
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  { colClass: 'description', text: 'Description', key: 'short_description', sortable: false },
  { colClass: 'statusDate', text: 'Dormant', key: 'status_updated_date', sortable: true },
  { colClass: 'owner', text: 'Owner', key: 'code', sortable: true },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'subvertical', text: 'Sub-Vertical', key: 'subvertical', sortable: true },
  { colClass: 'stage', text: 'Stage', key: 'last_investment_type', sortable: true },
  { colClass: 'score', text: 'TV Score', key: 'tv_company_score', sortable: true },
  {
    colClass: 'score',
    text: 'VC Score',
    key: 'tv_company_investor_score',
    sortable: true,
  },
  {
    colClass: 'monthsSinceInvestment',
    text: 'Round Date',
    key: 'months_since_last_investment',
    sortable: true,
  },
  { colClass: 'location', text: 'Location', key: 'location', sortable: true },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
];

const STATUSES = [
  { label: 'Prospect', count: 0, columns: PROSPECT_COLUMNS, active: true },
  { label: 'Portfolio', count: 0, columns: PORTFOLIO_COLUMNS, active: false },
  { label: 'Dormant', count: 0, columns: OTHER_COLUMNS, active: false },
  { label: 'Passed', count: 0, columns: OTHER_COLUMNS, active: false },
];

export const VERTICAL_FILTERS = [
  { label: 'All', name: 'All', active: true },
  { label: 'RE', name: 'Real Estate', longName: 'Real Estate', active: false },
  { label: 'FT', name: 'FinTech', longName: 'Financial Technology', active: false },
  { label: 'CI', name: 'Cloud', longName: 'Cloud Infrastructure', active: false },
  { label: 'CS', name: 'Security', longName: 'Cybersecurity', active: false },
  { label: 'MT', name: 'MarTech', longName: 'Marketing Technology', active: false },
  { label: 'AI', name: 'AI/ML', longName: 'AI/ML', active: false },
  { label: 'IT', name: 'Insurtech', longName: 'Insurance Technology', active: false },
];

export const OWNER_FILTERS = [
  { label: 'All', name: 'All', active: true },
  { label: 'AK', name: 'AK', active: false },
  { label: 'DB', name: 'DB', active: false },
  { label: 'NW', name: 'NW', active: false },
  { label: 'UP', name: 'UP', active: false },
  { label: 'AR', name: 'AR', active: false },
];

export const ENTITY_TYPE_FILTERS = [
  { label: 'All', name: 'All', active: true },
  { label: 'Company', name: 'Company', active: false },
  { label: 'Investor', name: 'Investor', active: false },
];

const Container = styled.div`
  .table-container {
    width: 100%;
    position: relative;

    @media (max-width: 1600px) {
      .column {
        &.description {
          display: none;
        }
      }
    }
    .column {
      &.owner,
      &.score {
        width: 6%;
        min-width: 80px;
      }
      &.stage {
        width: 8%;
        min-width: 80px;
      }
      &.subvertical {
        width: 14%;
        min-width: 100px;
      }
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    `margin-top: 70px;
    .toggle-view-btns {
      margin-bottom: 0;
      button {
        padding: 12px;
      }
    }
  `}
`;

function getParamStatus(location) {
  const STATUS_REGEX = 'status=[A-Za-z]*';
  const DEFAULT = 'prospect';

  const { search } = location;
  if (search.length === 0) {
    return DEFAULT;
  }
  const match = search.match(STATUS_REGEX);
  if (match.length === 0) {
    return DEFAULT;
  }

  const status = match[0].split('=')[1].toLowerCase();
  const validStatuses = STATUSES.map((s) => s.label.toLowerCase());
  if (validStatuses.includes(status)) {
    return status;
  }

  return DEFAULT;
}

const fetchCompanyOwnershipData = async () => {
  try {
    const sheetData = await getSheetsData(SPREADSHEET_ID, [
      'crmPermalink',
      'crmCost',
      'crmOwnership',
    ]);
    return sheetData;
  } catch (error) {
    captureError(`Error getting ownership data for Engaged page: ${error}`);
    return null;
  }
};

// const fetchCompanyFinancialsData = async () => {
//   try {
//     const sheetData = await getSheetsData(
//       COMPANY_FINANCIALS_SPREADSHEET_ID,
//       COMPANY_FINANCIALS_RANGES,
//     );
//     return sheetData;
//   } catch (error) {
//     captureError(`Error getting ownership data for Engaged page: ${error}`);
//     return null;
//   }
// };

const fetchEngagedCompanies = async () => {
  try {
    return await getEngagedCompanies();
  } catch (error) {
    captureError(`Error fetching engaged companies: ${error}`);
  }
};

const downloadProspects = async () => {
  try {
    const startDate = '2022-01-01';
    const endDate = getToday();
    const prospectData = await getProspectData(startDate, endDate);
    const prospectDataCSV = convertToCSV(prospectData);
    downloadCSV(prospectDataCSV, `ProspectData_${startDate}_${endDate}.csv`);
  } catch (error) {
    captureError(`Error downloading prospect data: ${error}`);
  }
};

export default function Engaged() {
  const paramStatus = getParamStatus(useLocation());

  const initialStatuses = useMemo(
    () =>
      STATUSES.map((s) => {
        s.active = s.label.toLowerCase() === paramStatus;
        return s;
      }),
    [paramStatus],
  );

  const [statuses, setStatuses] = useState(initialStatuses);
  const [companies, setCompanies] = useState([]);
  const [verticalFilters, setVerticalFilters] = useState(VERTICAL_FILTERS);
  const [ownerFilters, setOwnerFilters] = useState(OWNER_FILTERS);
  const [scoreFilter, setScoreFilter] = useState(false);
  const [hasCompanySheetsData, setHasCompanySheetsData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [users, setUsers] = useState([]);
  const isMobile = useIsMobile();

  const fetchEngagedData = async () => {
    const engagedCompanies = await fetchEngagedCompanies();

    if (engagedCompanies?.length > 0) {
      statuses.map((s) => {
        s.count = engagedCompanies.filter((c) => c.status === s.label).length;
        return s;
      });

      setStatuses([...statuses]);

      engagedCompanies.map((c) => {
        c.isRecent = isRecent(c.add_time); // not sure if this is being used
        c.days_outstanding = differenceInDays(new Date(), new Date(c.initial_contact_date));
        c.months_since_last_investment = differenceInMonths(
          new Date(),
          new Date(c.last_investment_date),
        );
        return c;
      });

      setCompanies([...engagedCompanies]);
    } else {
      setCompanies([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const allUsers = await getUsers();
      setUsers(allUsers);
    };

    fetchEngagedData();
    if (users.length === 0) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeStatus(e) {
    window.scrollTo(0, 0);
    const newStatus = e.target.value;
    const newStatuses = statuses.map((s) => ({ ...s, active: newStatus === s.label }));
    setStatuses(newStatuses);
    window.history.replaceState({}, '', `engaged?status=${newStatus}`);
  }

  const hasVertical = useCallback(
    (company) => {
      const activeVerticalFilter = verticalFilters.filter((v) => v.active);

      if (activeVerticalFilter.find((a) => a.name === 'All')) return true;

      const { tv_vertical, harmonic_verticals } = company;

      const companyVerticals = tv_vertical?.split(', ') ?? [];
      const harmonicVerticals = harmonic_verticals?.split(',') ?? [];

      const hasActiveVertical = activeVerticalFilter.some((a) => {
        const hasHarmonicVertical = harmonicVerticals.includes(a.longName);
        const hasCompanyVertical = companyVerticals.includes(a.name);
        return hasHarmonicVertical || hasCompanyVertical;
      });

      return hasActiveVertical;
    },
    [verticalFilters],
  );

  const hasOwner = useCallback(
    (company) => {
      const activeOwnerFilter = ownerFilters.filter((v) => v.active);

      if (activeOwnerFilter.find((a) => a.name === 'All')) return true;

      return company.code === activeOwnerFilter[0].name;
    },
    [ownerFilters],
  );

  function changeVertical(value) {
    window.scrollTo(0, 0);
    const newVertical = value;
    verticalFilters.forEach((v) => (v.active = newVertical === v.name));
    setVerticalFilters([...verticalFilters]);
  }

  function changeOwner(value) {
    window.scrollTo(0, 0);
    const newOwner = value;
    ownerFilters.forEach((v) => (v.active = newOwner === v.name));
    setOwnerFilters([...ownerFilters]);
  }

  const activePanel = useMemo(() => statuses.find((s) => s.active), [statuses]);

  const filteredCompanies = useMemo(
    () =>
      companies
        .filter((c) => {
          return (
            c.status === activePanel.label &&
            c.permalink !== 'humaninterest' &&
            // this is to prevent a duplicate
            !(
              (c.permalink === 'mynd' &&
                isSameDay(new Date(c.status_updated_date), new Date('2020-04-07T07:00:00.000Z'))) ||
              (c.permalink === 'mynd' &&
                isSameDay(new Date(c.status_updated_date), new Date('2020-04-06T07:00:00.000Z')))
            )
          );
        })
        .filter((c) => (scoreFilter ? c.tv_company_score >= 7 : true))
        .filter(hasVertical)
        .filter(hasOwner)
        .filter((c) => {
          if (filterValue.length === 0 || filterValue === '') return true;
          return c.company_name.toLowerCase().includes(filterValue.toLowerCase());
        }),
    [companies, activePanel, hasVertical, hasOwner, scoreFilter, filterValue],
  );

  useEffect(() => {
    statuses.map((s) => {
      if (activePanel.label === s.label) {
        s.count = filteredCompanies.filter((c) => c.status === s.label).length;
      } else {
        s.count = companies
          .filter((c) => (scoreFilter ? c.tv_company_score >= 7 : true))
          .filter(hasVertical)
          .filter(hasOwner)
          .filter((c) => c.status === s.label).length;
      }
      return s;
    });

    setStatuses([...statuses]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCompanies, companies]);

  useEffect(() => {
    if (activePanel.label === 'Portfolio' && companies?.length > 0) {
      if (!hasCompanySheetsData) {
        const fetchCompanySheetsData = async () => {
          setLoading(true);

          try {
            const ownershipData = await fetchCompanyOwnershipData();
            // const financialsData = await fetchCompanyFinancialsData();

            const updatedCompanies = companies.map((c) => {
              if (c.status === 'Portfolio') {
                if (ownershipData) {
                  const ownershipIndex = ownershipData.crmPermalink.findIndex((p) =>
                    p.includes(c.permalink),
                  );

                  if (ownershipIndex !== -1 && ownershipData.crmCost[ownershipIndex]?.length > 0) {
                    c.dollars_invested = formatMonetaryValue(
                      parseNum(ownershipData.crmCost[ownershipIndex][0]),
                    );
                  }
                }

                // if (financialsData) {
                //   const revenueData = parseRevenueData(c.permalink, financialsData);
                //   const annualRevenue = annualizeActualData(revenueData.values);
                // }
              }
              return c;
            });

            setCompanies([...updatedCompanies]);
          } catch (err) {
            captureError(`Error fetching sheets data for Engaged page: ${err}`);
          } finally {
            setLoading(false);
          }
        };

        fetchCompanySheetsData();
        setHasCompanySheetsData(true);
      }
    }
  }, [hasCompanySheetsData, activePanel.label, companies]);

  const noResultsCheck = useMemo(() => {
    return filteredCompanies.length === 0 && companies?.length > 0;
  }, [filteredCompanies, companies]);

  const hasError = useMemo(() => {
    return companies.length === 0 && !loading;
  }, [companies, loading]);

  const defaultSortKey = useMemo(() => {
    switch (activePanel.label) {
      case 'Prospect':
        return 'tv_company_score';
      case 'Portfolio':
        return 'status_updated_date';
      case 'Dormant':
        return 'status_updated_date';
      case 'Passed':
        return 'status_updated_date';
      default:
        return 'tv_company_score';
    }
  }, [activePanel.label]);

  return (
    <div>
      <Helmet>
        <title>Engaged - TV CRM</title>
      </Helmet>

      <PageHeader
        title="Engaged Companies"
        button={
          !isMobile && (
            <>
              <LinkButton href="/prospect_stats" className="tv-button prospect-stats-btn">
                Prospect Stats
              </LinkButton>
              <Flex minWidth="20px" />
              <Button onClick={downloadProspects} className="prospect-stats-btn">
                Download Prospects
              </Button>
            </>
          )
        }
      />

      <Container className="container page-container engaged-container" isMobile={isMobile}>
        <Flex direction="column" fill>
          <div
            className="toggle-view-btns btn-group flagged-btns tv-card"
            role="group"
            aria-label="..."
          >
            {statuses.map((s) => (
              <button
                key={`status-${s.label}`}
                type="button"
                onClick={changeStatus}
                className={`btn btn-default${s.active ? ' active' : ''}`}
                value={s.label}
              >
                {s.label} ({s.count})
              </button>
            ))}
          </div>
        </Flex>

        <FeedFilter
          withVerticalFilter
          handleOnVerticalChange={changeVertical}
          withScoreFilter={activePanel.label !== 'Portfolio' && !isMobile}
          handleScoreChange={() => setScoreFilter(!scoreFilter)}
          withOwnerFilter={activePanel.label !== 'Portfolio'}
          handleOwnerChange={changeOwner}
          padding={isMobile ? '10px 24px' : '12px 34px'}
          margin={isMobile ? '10px 0 10px 0' : '20px 0 20px 0'}
          withSearchInputFilter
          handleSearchInputChange={setFilterValue}
        />

        <div>
          <ListCompanyView
            companies={loading ? [] : filteredCompanies}
            isProspect
            noResults={noResultsCheck}
            hasError={hasError}
            columns={activePanel.columns}
            defaultSortKey={defaultSortKey}
            defaultIsAsc={false}
            withMargin={false}
            tableMaxHeightMobile="380px"
            tableMaxHeight="300px"
            withProspectButton={activePanel.label === 'Prospect'}
            refreshFeed={fetchEngagedData}
            users={users}
          />
        </div>
      </Container>
    </div>
  );
}
