import React, { useContext, useMemo, useState, useCallback } from 'react';
import { ProspectContext } from '../../contexts/ProspectContext';
import {
  patchProspect,
  getProspectScores,
  createNewProspectScores,
  deleteProspectScores,
  addCompanyToList,
  patchCompany,
  createNewFeedItem,
  // getUsers,
} from '../../BackendAPI';
import { STAGE_TO_TYPE_MAP } from '../../constants';
import { Select } from '../primitives';
import { prospectScores as allProspectScores } from '../../utils/prospectScores';
import { getUserFirstName, getUserEmail } from '../../utils/Auth';
// import { getUser } from '../../utils/Auth2';

const PORTFOLIO = 'Portfolio';
const PORTFOLIO_LIST_ID = 44;

const determineCompanyStatus = (prospectStatus) => {
  if (prospectStatus?.toLowerCase() === 'portfolio') {
    return 'Portfolio';
  }

  if (prospectStatus?.toLowerCase() === 'passed') {
    return 'Passed';
  }

  if (prospectStatus?.toLowerCase() === 'dormant') {
    return 'Dormant';
  }

  if (prospectStatus?.toLowerCase() === 'active') {
    return 'Prospect';
  }

  return null;
};

export default function ProspectSelect({ column, refreshFeed }) {
  const [state, dispatch] = useContext(ProspectContext);
  const [hasChanged, setChanged] = useState(false);
  const { options, label, subLabel } = state[column];
  // const user = getUser();
  const activeValue = options.find((o) => o.active)?.value;

  const initialStageTypeId = useMemo(() => {
    const initalStage = state.stage.options.find((o) => o.active)?.value;
    return STAGE_TO_TYPE_MAP[initalStage];
  }, [state.stage.options]);

  const initialValue = useMemo(() => {
    if (!state.isEditing && !hasChanged) return 'N/A';
    return activeValue;
  }, [state?.isEditing, hasChanged, activeValue]);

  const updateProspectStatusDependencies = useCallback(
    async (newStatus) => {
      dispatch({ type: 'UPDATE_STATUS', payload: newStatus });
      await patchCompany(state.permalink, { major_status: determineCompanyStatus(newStatus) });

      if (newStatus === PORTFOLIO) {
        await addCompanyToList(state.permalink, PORTFOLIO_LIST_ID, getUserEmail());
      }

      const firstName = getUserFirstName();
      const statusHeader = `updated to ${newStatus} by ${firstName}`;
      const portfolioHeader = `added to Portfolio by ${firstName}`;
      const header = newStatus === PORTFOLIO ? portfolioHeader : statusHeader;

      await createNewFeedItem({
        permalink: state.permalink,
        type: 'status',
        header,
        custom: `{"status": "${newStatus}", "user": "${firstName}"}`,
      });

      await refreshFeed?.();
    },
    [dispatch, state, refreshFeed],
  );

  const updateValue = useCallback(
    async (value) => {
      setChanged(true);
      const payload = { key: column, value };
      dispatch({ type: 'UPDATE_PROPERTY', payload });

      const { prospectId } = state;

      if (prospectId) {
        const { dbColumn } = state[column];
        const body = {};
        body[dbColumn] = value;

        await patchProspect(prospectId, body);

        if (column === 'stage') {
          const stageTypeId = STAGE_TO_TYPE_MAP[value];

          if (stageTypeId && stageTypeId !== initialStageTypeId) {
            await patchProspect(prospectId, { stage_type_id: stageTypeId });

            await deleteProspectScores(prospectId);

            const allScores = allProspectScores.filter((s) => s.stage_type_id === stageTypeId);
            await createNewProspectScores({ prospect_id: prospectId, prospect_scores: allScores });

            const prospectScores = await getProspectScores(state.prospectId);
            dispatch({
              type: 'UPDATE_PROSPECT_SCORES',
              payload: { prospectScores, stageTypeId },
            });
          }
        }

        if (column === 'status' && value !== state?.companySummary?.major_status) {
          updateProspectStatusDependencies(value);
        }
      }
    },
    [initialStageTypeId, column, dispatch, state, updateProspectStatusDependencies],
  );

  // const setDefaultOwner = useCallback(async () => {
  //   const allUsers = await getUsers();
  //   const userName = allUsers.find((u) => {
  //     return u.first_name === user.first_name;
  //   });

  //   if (userName?.user) {
  //     updateValue(userName.user);
  //   }
  // }, [user, updateValue]);

  // useEffect(() => {
  //   if (!state.prospectId) {
  //     if (column === 'owner') {
  //       setDefaultOwner();
  //     }
  //     if (column === 'status') {
  //       updateValue('Active');
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onChange = useCallback(
    async (event) => {
      event.preventDefault();

      setChanged(true);

      const { value } = event.target;
      updateValue(value);
    },
    [updateValue],
  );

  return (
    <Select
      label={label}
      subLabel={subLabel}
      options={options}
      initialValue={initialValue}
      onChange={onChange}
    />
  );
}
