import React, { useContext, useEffect, memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { differenceInMonths, format } from 'date-fns';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Line } from 'recharts';
import { camelCaseToSentenceCase, truncateNumber, calculatePercentageDifference } from '../../util';
import { getCompanyTractionSignals, getTractionPercentile } from '../../BackendAPI';
import { VERT_MAP } from '../../constants';
import { Flex, Loader, Card } from '../primitives';
import { CompanyVerticalContext } from '../../contexts/CompanyVerticalContext';

const StyledCard = styled(Card)`
  padding: 18px 12px 6px 12px;
  margin-bottom: 4px;
  .label {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 12px;
    padding-bottom: 2px;
    border-radius: 0;
    label {
      font-size: 12.5px;
      color: #292b2c;
      &:last-child {
        font-weight: bold;
      }
    }
    padding-bottom: 6px;
  }
`;

const MetricContainer = styled(Flex)`
  padding: 0 8px;
  margin-bottom: 10px;
  .score {
    font-size: 12px;
  }
  &.mb {
    margin-bottom: 20px;
  }
`;

const groupByMetricName = (data) =>
  data.reduce((result, item) => {
    const metricName = item.metric_name;

    if (!result[metricName]) {
      result[metricName] = [];
    }

    result[metricName].push(item);

    return result;
  }, {});

const getTickWidth = (metric) => {
  const truncatedNumber = truncateNumber(metric.metric_value);
  switch (truncatedNumber.length) {
    case 1:
      return 17;
    case 2:
      return 22;
    case 3:
    default:
      return 30;
  }
};

const CompanyTractionSignals = ({ permalink }) => {
  const [tractionSignalData, setTractionSignalsData] = useState([]);
  const [tractionPercentileData, setTractionPercentileData] = useState({});
  const [state] = useContext(CompanyVerticalContext);
  const { verticals } = state;

  useEffect(() => {
    async function fetchData() {
      verticals.forEach(async (vertical) => {
        if (vertical.checked) {
          const data = await getTractionPercentile(permalink, vertical.short_vertical);
          data.forEach((item) => {
            setTractionPercentileData((prevData) => ({
              ...prevData,
              [item.metric_name]: {
                ...prevData[item.metric_name],
                percent_change: item.percent_change,
                [vertical.short_vertical]: item.percentile,
              },
            }));
          });
        }
      });
    }
    fetchData();
  }, [permalink, verticals]);

  const fetchCompanyTractionSignals = useCallback(async () => {
    try {
      const data = await getCompanyTractionSignals(permalink);

      const groupedByMetricName = groupByMetricName(data);

      // sort each array in the object by date
      Object.entries(groupedByMetricName).forEach(([metricName, metricArray]) => {
        let metrics = metricArray.sort((a, b) => a.metric_dt.localeCompare(b.metric_dt));
        const mostRecent = metricArray[metricArray.length - 1].metric_dt;

        metrics = metricArray.filter((metric) => {
          const yearsDifference = differenceInMonths(
            new Date(mostRecent),
            new Date(metric.metric_dt),
          );
          return yearsDifference <= 6;
        });

        groupedByMetricName[metricName] = metrics;
      });

      if (Object.entries(groupedByMetricName)?.length > 0) {
        setTractionSignalsData(groupedByMetricName);
      } else {
        setTractionSignalsData(null);
      }
    } catch (error) {
      setTractionSignalsData(null);
    }
  }, [permalink]);

  useEffect(() => {
    if (permalink) {
      fetchCompanyTractionSignals();
    }
  }, [permalink, fetchCompanyTractionSignals]);

  if (tractionSignalData?.length === 0) {
    return (
      <Flex align="center" justify="center" fill height="300px">
        <Loader />
      </Flex>
    );
  }

  if (!tractionSignalData) {
    return (
      <Flex className="content-container card" fill>
        <div className="card-block">
          <h3>Traction Signals</h3>
          <Flex direction="column">
            <label>No Data</label>
          </Flex>
        </div>
      </Flex>
    );
  }

  return (
    <div className="content-container card">
      <div className="card-block">
        <h3>Traction Signals</h3>

        <Flex direction="column">
          {Object.entries(tractionSignalData).map(([metricName, metricArray]) => {
            const lowestValue = metricArray[0].metric_value;
            const highestValue = metricArray[metricArray.length - 1].metric_value;
            return (
              <StyledCard key={metricName} className="card-block" direction="column" fill>
                <Flex className="label" justify="space-between" fill>
                  <label>{camelCaseToSentenceCase(metricName)}</label>
                  <label>{calculatePercentageDifference(lowestValue, highestValue)}</label>
                </Flex>

                <MetricContainer justify="space-between" fill>
                  <label>Growth Last 90 Days</label>
                  <label className="score">
                    {tractionPercentileData[metricName]
                      ? `${Math.round(tractionPercentileData[metricName]?.percent_change)}%`
                      : '-'}
                  </label>
                </MetricContainer>

                {verticals.map(
                  (vertical) =>
                    vertical.checked &&
                    tractionPercentileData[metricName]?.[vertical.short_vertical] && (
                      <MetricContainer
                        key={vertical.short_vertical}
                        justify="space-between"
                        fill
                        align="center"
                        className="mb"
                      >
                        <Flex justify="center" align="center">
                          <span
                            className={`vertical-item ${slugify(
                              vertical.short_vertical,
                            ).toLowerCase()} small`}
                            key={vertical.short_vertical}
                          >
                            {VERT_MAP[vertical.short_vertical]}
                          </span>
                          <label>Percentile</label>
                        </Flex>
                        <label className="score">
                          {tractionPercentileData[metricName]?.[vertical.short_vertical]}%
                        </label>
                      </MetricContainer>
                    ),
                )}

                <ResponsiveContainer width="100%" height="100" aspect={1.4}>
                  <LineChart width={300} height={100} data={metricArray}>
                    <Line type="monotone" dataKey="metric_value" stroke="#8884d8" strokeWidth={2} />
                    <XAxis
                      dataKey="metric_dt"
                      type="category"
                      tick={{ fontSize: 10 }}
                      tickFormatter={(value) => format(new Date(value), 'M/yy')}
                    />
                    <Tooltip formatter={(value) => [value, null]} labelFormatter={() => ''} />
                    <YAxis
                      dataKey="metric_value"
                      tick={{ fontSize: 10 }}
                      width={getTickWidth(metricArray[metricArray.length - 1])}
                      type="number"
                      tickCount={3}
                      // domain={['auto', 'auto']}
                      domain={[
                        lowestValue < 10 ? 0 : 'auto',
                        lowestValue < 10 ? highestValue + lowestValue : 'dataMax',
                      ]}
                      allowDecimals={false}
                      tickFormatter={truncateNumber}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </StyledCard>
            );
          })}
        </Flex>
      </div>
    </div>
  );
};

export default memo(CompanyTractionSignals);
