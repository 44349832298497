import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import '../../styles/react-datetime.css';
import styled from 'styled-components';
import { capitalize } from '../../util';
import { ProspectContext, getActiveValue } from '../../contexts/ProspectContext';
import { Card, Flex } from '../primitives';
import useIsMobile from '../../hooks/useIsMobile';

const formatDateStr = (dt) => {
  if (!dt) return 'N/A';
  const monthList = dt.slice(0, 10).split('-');
  return `${monthList[1]}/${monthList[2]}/${monthList[0].slice(2, 4)}`;
};

const StyledFlex = styled(Flex)`
  .prospect-data-container {
    &:first-child {
      width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
      ${({ isMobile }) => isMobile && `margin-bottom: 20px;`}
    }
    &:last-child {
      width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
    }
  }
  ul {
    margin-bottom: 0;
    li {
      label {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      p.green {
        color: green;
      }
      p.orange {
        color: orange;
      }
      p.red {
        color: red;
      }
      p.blue {
        color: #2579a9;
      }
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    `
    label {
      white-space: nowrap;
    }
  `}
`;

const formatCompanyStatus = (coStatus, onWatchlist, verticalPriority) => {
  if (!coStatus) return '-';

  if (coStatus) {
    if (coStatus === 'Active') return 'Prospect';
    return capitalize(coStatus);
  }

  if (verticalPriority) return 'Vertical Priority';

  if (onWatchlist) {
    return 'Watchlist';
  }

  return '-';
};

export default function CompanyProspect({ summary, onWatchlist, isVerticalPriority }) {
  const [prospectContextState, dispatch] = useContext(ProspectContext);
  const isMobile = useIsMobile();
  const { owner, stage, source, status, initialContactDate, prospectId, sourceType, awareTv } =
    prospectContextState;
  const activeStatus = getActiveValue(status.options);
  const isActive = useMemo(() => !!prospectId, [prospectId]);

  if (isActive) {
    return (
      <Card className="card prospects-block" marginBottom="20" fill>
        <div className="card-block">
          <h3>Prospect Details</h3>

          <StyledFlex isMobile={isMobile} direction={isMobile ? 'column' : 'row'}>
            <Flex className="prospect-data-container">
              <Flex direction="column" width="50%">
                <ul className="list-unstyled list-spaced">
                  <li>
                    <label>Owner</label>
                    <p>{getActiveValue(owner.options)}</p>
                  </li>
                  <li>
                    <label>Stage</label>
                    <p>{getActiveValue(stage.options)}</p>
                  </li>
                </ul>
              </Flex>
              <Flex direction="column">
                <ul className="list-unstyled list-spaced">
                  <li>
                    <label>Contact Date</label>
                    <p>{formatDateStr(initialContactDate.value)}</p>
                  </li>
                  <li>
                    <label>Source</label>
                    <p>{getActiveValue(source.options)}</p>
                  </li>
                </ul>
              </Flex>
            </Flex>

            <Flex className="prospect-data-container">
              <Flex direction="column" width="50%">
                <ul className="list-unstyled list-spaced">
                  <li>
                    <label>{isMobile ? 'Type' : 'Source Type'}</label>
                    <p>{getActiveValue(sourceType.options)}</p>
                  </li>
                  <li>
                    <label>TV Awareness</label>
                    <p>{getActiveValue(awareTv.options)}</p>
                  </li>
                </ul>
              </Flex>
              <Flex direction="column">
                <ul className="list-unstyled list-spaced">
                  <li>
                    <label>Status</label>
                    <p
                      className={classnames({
                        green: activeStatus === 'Active' || activeStatus === 'Prospect',
                        orange: activeStatus === 'Dormant',
                        red: activeStatus === 'Passed',
                        blue: activeStatus === 'Portfolio',
                      })}
                    >
                      {formatCompanyStatus(activeStatus, onWatchlist, isVerticalPriority)}
                    </p>
                  </li>
                  <li>
                    <label>Company Score</label>
                    {Number(summary?.tv_company_score) > 0 ? (
                      <p
                        className={classnames({
                          orange:
                            Number(summary?.tv_company_score) >= 5 &&
                            Number(summary?.tv_company_score) <= 6,
                          red: Number(summary?.tv_company_score) <= 4,
                          green: Number(summary?.tv_company_score) >= 7,
                        })}
                      >
                        {`${summary.tv_company_score}/10`}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </li>
                </ul>
              </Flex>
            </Flex>
          </StyledFlex>

          <button
            type="button"
            className="tv-button"
            onClick={() => {
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: true } });
            }}
          >
            Edit
          </button>
        </div>
      </Card>
    );
  }

  return null;
}
